import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import styled from 'styled-components';

import { AppState } from '@redux/types';
import {
  ProfileCountry,
  PurchasedSubscription,
} from '@redux/reducers/profile/types';
import { _getCountries } from '@redux/reducers/countries/selectors';
import {
  _getProfile,
  _getPurchasedSubscriptions,
} from '@redux/reducers/profile/selectors';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { getPurchasedInfo } from '@redux/reducers/profile/action';
import { login, logout } from '@redux/reducers/auth/action';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import CSSMediaQuery, { ShowOn } from '../atoms/CSSMediaQuery/CSSMediaQuery';
import Image from '../atoms/Image/Image';
import Text from '../atoms/Text/Text';
import InfoPopupLogout from '@components/organisms/InfoPopupLogout';

import { BillingHistory } from '@shared/interfaces/billing-history';
import { Color } from '@shared/enums/button-color';
import { HaveAccountPopup } from '@hoc/HaveAccountPopup';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Size } from '@shared/enums/button-size';
import { withTranslation } from 'i18n';

const BoxAppNameInLine = styled(Box)`
  z-index: 1;
  box-shadow: 0 0 6px 0 ${({ theme }) => theme.transparentColors.black25};
`;

const WrapperInner = styled(Box)`
  display: flex;
  align-items: center;

  margin-left: auto;
`;

const ButtonExtend = styled(Button)`
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-size: 0.75em;
  height: 2.375em;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
`;

const BoxReseller = styled(Box)`
  display: flex;
`;

type AppNameInLineProps = {
  className?: string;
  country: ProfileCountry;
  isAuthorized?: boolean;
  resellerImage?: string;
  dispatch: (data: any) => void;
  subscriptions?: PurchasedSubscription[];
  billingHistory?: BillingHistory[];
  t: I18nTranslation;
  showHaveAccountPopup?: (
    p: () => void,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
};

class AppNameInLine extends PureComponent<AppNameInLineProps> {
  state = {
    isOpenLogoutPopup: false,
  };

  handleShow = () => {
    this.setState({
      isOpenLogoutPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpenLogoutPopup: false,
    });
  };

  handleLogout = () => {
    this.handleClose();
    this.props.dispatch(logout());
  };

  handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const {
      isAuthorized,
      dispatch,
      subscriptions,
      billingHistory,
      showHaveAccountPopup,
    } = this.props;

    const isDiscount = subscriptions.length || billingHistory.length;

    if (isAuthorized && isDiscount) {
      this.handleShow();
    } else if (isAuthorized) {
      dispatch(logout());
    } else {
      showHaveAccountPopup(async () => {
        try {
          await dispatch(login());
        } catch (error) {
          console.warn(error);
        }
      }, e);
    }
  };

  componentDidMount() {
    this.props.dispatch(getPurchasedInfo());
  }

  render() {
    const { isAuthorized, resellerImage, className, t } = this.props;

    let buttonColorType = Color.FullGreen;
    let buttonText = t('login');

    if (isAuthorized) {
      buttonColorType = Color.TransparentGrey;
      buttonText = t('logout');
    }

    return (
      <>
        <BoxAppNameInLine
          className={className}
          smPr={0.875}
          smPl={0.875}
          pt={1.125}
          pr={1.75}
          pb={1.125}
          pl={1.75}
          backgroundColor="white"
        >
          {resellerImage && (
            <CSSMediaQuery showOn={[ShowOn.Tablet, ShowOn.Desktop]}>
              <BoxReseller backgroundColor="white">
                <Box mr={0.5}>
                  <Text
                    size={1.125}
                    color={Color.GrayChateau}
                    lineHeight="normal"
                  >
                    in partnership with
                  </Text>
                </Box>
                <Image src={resellerImage} height={2} />
              </BoxReseller>
            </CSSMediaQuery>
          )}

          <WrapperInner>
            <Box pl={0.875}>
              <ButtonExtend
                colorType={buttonColorType}
                sizeType={Size.Small}
                onClick={this.handleClick}
              >
                {buttonText}
              </ButtonExtend>
            </Box>
          </WrapperInner>
        </BoxAppNameInLine>

        <InfoPopupLogout
          isOpen={this.state.isOpenLogoutPopup}
          onLogout={this.handleLogout}
          onClose={this.handleClose}
        />
      </>
    );
  }
}

const styledAppNameInLine = styled(withTranslation('common')(AppNameInLine))`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const withConnect = connect((state: AppState) => {
  const profile = _getProfile(state);

  return {
    country: profile.country,
    isAuthorized: _isAuthorized(state),
    countries: _getCountries(state),
    subscriptions: _getPurchasedSubscriptions(state),
    billingHistory: profile.billingHistory,
  };
});

export default HaveAccountPopup(withConnect(styledAppNameInLine));
