import React, { PureComponent } from 'react';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import { Col, Row } from '../atoms/Grid';
import Text from '../atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { AnimationType } from '@shared/enums/animation-type';
import BottomMessage from './BottomMessage';
import { Color } from '@shared/enums/button-color';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { Size } from '@shared/enums/button-size';
import { withTranslation } from 'i18n';

type InfoPopupLogoutProps = {
  onLogout: () => void;
  isOpen: boolean;
  onClose: () => void;
  t: I18nTranslation;
};

class InfoPopupLogout extends PureComponent<InfoPopupLogoutProps> {
  t = key => this.props.t(`logoutPopup.${key}`);

  render() {
    return (
      <BottomMessage
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        animationType={AnimationType.SlideExtend}
      >
        <Box pl={1.5} pr={1.5} pt={1.5} pb={0.875}>
          <Box pb={0.625}>
            <Text color="middleGrey" lineHeight={1.4}>
              {this.t('warning')}
            </Text>
          </Box>
          <Box pb={1.5}>
            <Text color="middleGrey" lineHeight={1.4}>
              {this.t('confirmation')}
            </Text>
          </Box>
          <Row>
            <Col xs={6}>
              <Button
                colorType={Color.FullBlue}
                sizeType={Size.Medium}
                onClick={this.props.onClose}
              >
                {this.t('cancel')}
              </Button>
            </Col>
            <Col xs={6}>
              <Box align={Align.Right}>
                <Button
                  colorType={Color.FullRed}
                  sizeType={Size.Medium}
                  onClick={this.props.onLogout}
                >
                  {this.t('logout')}
                </Button>
              </Box>
            </Col>
          </Row>
        </Box>
      </BottomMessage>
    );
  }
}

export default withTranslation('common')(InfoPopupLogout);
